import React from 'react';
import './TopNav.css';
import ApplicationForm from '../views/ApplicationForm';
import ContactForm from '../views/ContactForm';
import {Link} from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button } from 'reactstrap';

export default class TopNav extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      showApplicationForm:false,
      showContactForm:false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  showApplicationForm = () => {
    this.setState({showApplicationForm: true}); 
  };
  closeApplicationForm = () => {
      this.setState({showApplicationForm: false}); 
  };
  showContactForm = () => {
    this.setState({showContactForm: true}); 
  };
  closeContactForm = () => {
    this.setState({showContactForm: false}); 
  };
  render() {
    return (
      <div>
        {this.state.showApplicationForm&&
          <ApplicationForm handleClose={this.closeApplicationForm}/>
        }
        {this.state.showContactForm &&
          <ContactForm handleClose={this.closeContactForm}/>
        }
        <Navbar className="mainNavbar" light expand="md">
          <NavbarBrand href="/"><img alt="Buggy Logo" width={"150"} src={require("../images/logo-buggy.svg")} /></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/#how-it-works">HOW IT WORKS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/faq">FAQ</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  CURRENT DRIVERS
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={this.showContactForm}>
                    Report An Issue
                  </DropdownItem>
                  <DropdownItem>
                    <a href="/pickup">Run pickup</a>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem>
                    <a href="/book_car">BOOK A CAR</a>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink><Button onClick={this.showApplicationForm}>APPLY</Button></NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

// export default class TopNav extends React.Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       showApplicationForm:false,
//       showContactForm:false,
//     }
//   }
//   showApplicationForm = () => {
//     this.setState({showApplicationForm: true}); 
//   };
//   closeApplicationForm = () => {
//       this.setState({showApplicationForm: false}); 
//   };
//   showContactForm = () => {
//     this.setState({showContactForm: true}); 
//   };
//   closeContactForm = () => {
//     this.setState({showContactForm: false}); 
//   };
//   render() {
//     return  <nav className="navbar navbar-expand-sm navbar-static-top navbar-light buggy-nav-bar-main">
//     <div className="container">
//         {this.state.showApplicationForm &&
//           <ApplicationForm handleClose={this.closeApplicationForm}/>
//         }
//         {this.state.showContactForm &&
//           <ContactForm handleClose={this.closeContactForm}/>
//         }
//         <Link className="navbar-brand" to="/"><img alt="Buggy Logo" width={"150"} src={require("../images/logo-buggy.svg")} /></Link>
//         <button className="navbar-toggler" style={{"color":"#fff"}} type="button" data-toggle="collapse" data-target="#navbar1">
//             <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className="collapse navbar-collapse" id="navbar1">
//             <ul className="navbar-nav ml-auto" >
//                 <li className={"nav-item"}>
//                   <a className="nav-link" href="/#how-it-works">HOW IT WORKS</a>
//                 </li>
//                 <li className={"nav-item"}>
//                   <a className="nav-link" href="/#faq">FAQ</a>
//                 </li>
//                 <li className={"nav-item"}>
//                   <a className="nav-link" href="#">CURRENT DRIVER</a>
//                   <ul className="innerNav">
//                     <li onClick={this.showContactForm}>Report An Issue</li>
//                     <li><a href="/pickup">Run pickup</a></li>
//                   </ul>
//                 </li>
//                 <li className="nav-item" onClick={this.showApplicationForm}>
//                     <button className="btn btn-default nav-book-car-btn">BOOK A CAR</button>
//                 </li>
//             </ul>
//         </div>
//     </div>
//   </nav>
//   }
// }