import React, {Component} from 'react';
import { Row, Col, Media, Button } from 'reactstrap';
import HyundaiSonata2017 from '../images/2017_Hyundai_Sonata.png';
import HyundaiElantra from '../images/hyundai_elantra.jpg';
import NissanAltima from '../images/nissan_altima.jpg';
import Chrysler200 from '../images/chrysler_200.png';
import NissanRouge from '../images/nissan_rouge.jpg';
import NissanSentra2017 from '../images/2017_nissan_sentra.jpg';
import Camry2018 from '../images/camry18.jpg';
import Sienna2018 from '../images/sienna_2018.png';


class Pricing extends Component {
      
  render() {
    return (
        <Row className="policies">
            <Col xs={12}>
                <h2 className="mainTitle">PRICING</h2>
            </Col>
            <Col xs={12} sm={{size: 4}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={HyundaiSonata2017} width="100%" className="textCenter" alt="Buggy TLC Policies"/>
                        </Media>
                    </Media>
                </div>
                <h6>HYUNDAI SONATA</h6>
                <p>Starting from $369</p>
                <Button outline size="sm" color="primary">Book Now</Button>
            </Col>
            <Col xs={12} sm={{ size: 4}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={HyundaiElantra} width="100%" className="textCenter" alt="Buggy TLC Payments"/>
                        </Media>
                    </Media>
                </div>
                <h6>HYUNDAI ELANTRA</h6>
                <p>Starting from $369</p>
                <Button outline size="sm" color="primary">Book Now</Button>
            </Col>
            <Col xs={12} sm={{ size: 4}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={NissanAltima} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>NISSAN ALTIMA</h6>
                <p>Starting from $389</p>
                <Button outline size="sm" color="primary">Book Now</Button>
            </Col>
            <Col xs={12} sm={{ size: 4}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Chrysler200} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>CHRYSLER 200</h6>
                <p>Starting from $369</p>
                <Button outline size="sm" color="primary">Book Now</Button>
            </Col>
            <Col xs={12} sm={{ size: 4}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={NissanRouge} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>NISSAN ROGUE</h6>
                <p>Starting from $489</p>
                <Button outline size="sm" color="primary">Book Now</Button>
            </Col>
            <Col xs={12} sm={{ size: 4}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={NissanSentra2017} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>NISSAN SENTRA</h6>
                <p>Starting from $379</p>
                <Button outline size="sm" color="primary">Book Now</Button>
            </Col>
            <Col xs={12} sm={{ size: 4}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Camry2018} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>TOYOTA CAMRY</h6>
                <p>Starting from $399</p>
                <Button outline size="sm" color="primary">Book Now</Button>
            </Col>
            <Col xs={12} sm={{ size: 4}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Sienna2018} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>TOYOTA SIENNA (WAV)</h6>
                <p>Starting from $299</p>
                <Button outline size="sm" color="primary">Book Now</Button>
            </Col>
        </Row>
        )}
}
export default Pricing