import React, {Component} from 'react';
import './EmailForm.css';
import Input from './Input';
import ApplicationForm from './ApplicationForm';
import { Button  } from 'reactstrap';
import { compose,withApollo } from 'react-apollo';
import { CreateContactInfoMutation } from '../Functions/mutations';

class EmailForm extends Component {
    constructor(props) {
        super(props);
        let input = {};
        this.state = {
            input:input,
            display: true,
            showApplicationForm:false,
            isFormValid:false,
            contactType:null,
            validContact:null
        }
    }
    createContactInfo = (type,contact) => {
        let input = {contact:contact,type:type};
        this.props.client.mutate({
            mutation: CreateContactInfoMutation,
            variables: {input}
        }).then((result)=>{
        });
    };
    submitEmailForm = () => {
        let contact = this.state.validContact;
        this.createContactInfo(contact.type,contact.contact);
        //Send fetch to db to capture email
        this.setState({showApplicationForm:true});
    };
    closeApplicationForm = () => {
        this.setState({showApplicationForm:false}); 
    };
    updateInput = (e) => {
        if(e.target.name==="emailOrPhone"){
            let inputValue = e.target.value;
            let inputType = null;
            if (inputValue.includes("@") && inputValue.includes(".") && inputValue.length > 4){
                let validContact = {type:"email",contact:inputValue}
                this.setState({isFormValid:true,validContact:validContact});
            }else if(inputValue.length > 8){
                inputValue = inputValue.replace("+1", "");
                inputValue = inputValue.replace(/-/g, "");
                if(inputValue.length === 10 && Number(inputValue) === parseInt(inputValue)){
                    let validContact = {type:"phone",contact:"+1"+inputValue}
                    this.setState({isFormValid:true,validContact:validContact}); 
                }else{
                    this.setState({isFormValid:false}); 
                }
            }
        }
        let input = this.state.input;
        input[e.target.name] = e.target.value;
        this.setState({input:input}); 
    }
    render() {
        return <div className="emailForm">
                {this.state.showApplicationForm&& <ApplicationForm initialContact={this.state.validContact} handleClose={this.closeApplicationForm}/>}
                <Input style={{borderRadius:"10px"}} onChange={this.updateInput} errorMessage="Please enter correctly" name="emailOrPhone" label="Enter your email or phone" fullWidth={true} placeholder="Enter your email or phone number to start" type="text"/>
                <Button className="ctaButton" onClick={this.submitEmailForm} disabled={!this.state.isFormValid}>Get Started</Button>
            </div>
    }
}

export default compose(withApollo)(EmailForm);