import React from 'react';
import './Accordion.css';

export default class Accordion extends React.Component {
    constructor(props) {
        super(props)
        this.state = {display: false}
    }
    toggleDisplay = () => {
        let currentState = this.state.display;
        this.setState({display: !currentState});
    };
    render() {
        return <div className="accordion">
        <header onClick={this.toggleDisplay}>{this.props.title} <i className="fa fa-chevron-down"></i></header>
        <div className={this.state.display ?"accordion-content open":"accordion-content closed"}>
            {this.props.children}
        </div>
        </div>
    }
}