import React, {Component} from 'react';
import WeekendVehicle from '../images/weekend_rental.png';
import './faq.css'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Collapse, CardBody } from 'reactstrap';

const listOfFaqs = 
{   "Applications":
    [
        {
            question: "Q: What is Buggy?:",
            answer: "A: Buggy is a TLC Car Rental Company. If you have a TLC License and you are looking to drive for for Uber, Lyft, Juno, Gett, or other taxi/cab services, Buggy is just for you!"
        },
        {
            question: "Q: How do I sign up to begin renting a vehicle?",
            answer: "A: To begin your application, click here: Apply to Rent a Buggy Vehicle"
        },
        {
            question: "Q: How can I find out which cars are available to rent?",
            answer: "A: Once you apply through the link provided above, you will receive a list of the vehicles that are available at that time."
        },
        {
            question: "Q: How do I make an appointment to pick up the vehicle?",
            answer: "A: After you apply, we will contact you to set up an appointment to pick up the vehicle."
        },
        {
            question: "Q: What is the location to pick up the vehicle?",
            answer: "A: Our Buggy office and pick up spot is located at 389 Empire Blvd Brooklyn, NY 11225."
        },
        {
            question: "Q: What is the minimum age requirement to rent from Buggy?",
            answer: "A: The minimum age requirement is 21 years old."
        },
        {
            question: "Q: What kind of license do I need?",
            answer: "A: You are required to have a TLC License, and valid DMV license."
        },
        {
            question: "Q: Which documents do I need to bring with me to the pickup location to get into a vehicle?",
            answer: "A: Make sure to bring your TLC, and DMV License, Social Security, Banking Information, your Credit Card and Debit Card, and money to pay for your Security Deposit."
        },
        {
            question: "Q: Can I work for more than one company while renting a vehicle from your company?",
            answer: "A: Yes! You can work for more than one company, ie; both Uber, and Lyft, or any other car service companies at the same time while renting a vehicle from Buggy."
        },
        {
            question: "Q: Can the car be used for personal use?",
            answer: "A: Yes, as long as you are the only person driving the vehicle."
        },
        {
            question: "Q: Can I drive anywhere with the car?",
            answer: "A: Yes, As long as you continue paying your rent on time and you are responsible for all charges you may incur while in the vehicle, ie; toll, tickets etc."
        },
        {
            question: "Q: Is there a limited amount of mileage?",
            answer: "A: No, you can drive the car as far as you would like, However once outside the 60 miles perimeter of Buggy Office you will be responsible for getting tow and Buggy will reimburse for the tow cost."
        },
        {
            question: "Q: Do I need to pay a Security Deposit?",
            answer: "A: Yes. You are required to pay a  refundable deposit of $399, before picking up a vehicle. (Please note, deposit amount may be subject to change based on your insurance)."
        },
        {
            question: "Q: Why am i required to pay a deposit?",
            answer: "A: We require a deposit as a security, just in case you damage the vehicle or incur any charges due to fees from tickets, tolls etc."
        },
        {
            question: "Q: When do I get my deposit back?",
            answer: "A: You may receive your security deposit 30 days after returning the vehicle. We hold it for 30 days in case of any tickets or toll charges that may take about 30 or more days to process."
        }
    ],
    "Billing":
    [
        {
            question: "Q: When is the rent due?",
            answer: "A: Buggy’s weekly billing cycle is very simple to understand. Your first rental payment will be due exactly 1 week  from the day you pick up the vehicle, and every 7 days after that. For example, if you picked up the vehicle on a Monday, your first rental payment will be due 7 days later on that Sunday, and every Sunday moving forward. If you picked the car up on a Wednesday, your rent will be due 7 days later on Tuesday, and every Tuesday moving forward, and so forth. You can always contact one of our Buggy representatives to confirm your rental due date if you are not sure. "
        },
        {
            question: "Q: Which payment methods are available to pay for my rent?",
            answer: "A: We have a few different methods of payment available. We accept direct payments through Zelle or Quick Pay from participating bank accounts, Remote Cash payments at any of our participating locations (Please see our FAQ regarding the details of Remote Cash), or  of course you can pay with your debit or credit card. (Please note, additional fees may apply when paying with your credit or debit card)."
        },
        {
            question: "Q: What is Instant Pay?",
            answer: "A: Instant Pay means your earning are available to cash out from your Uber app instantly, and there is no need to wait for a weekly payment. You can cash out with Instant Pay up to 5 times per day. This will be very helpful to you so you can make sure to pay your weekly rent on time. You must be sure to set up Instant Pay with Uber."
        }
    ],
    "Returns":
    [
        {
            question: "Q: What is the minimum amount of time I can rent a vehicle for?",
            answer: "A: We have a one week minimum policy."
        },
        {
            question: "Q: What is the return policy?",
            answer: "A: If you are looking to return the vehicle, you must schedule your return at least one full week, or more, in advance through text only. To schedule your return, text Buggy at 347-334-6315 and request a link to schedule your return. You will then be prompted to choose the exact date that you would like to return the car, and to let us know your reason for returning."
        },
        {
            question: "Q: What happens if I do not schedule my return through the link at least one full week in advance?",
            answer: "A: Anyone who does not schedule their return at least one full week in advance, may be subject to a penalty fee amount equal to a full additional week of rent."
        },
        {
            question: "Q: What time of day do I need to return the car?",
            answer: "A: All drivers with vehicles scheduled to return, must bring the car in at 8 AM - 9 AM on Monday-Friday, and on Sunday from 9 AM - 10 AM."
        },
        {
            question: "Q: What happens if I bring the car later than 10 AM?",
            answer: "A: Anyone who brings the vehicle later than the appointed time, may be subject to a penalty late fee of $150 plus the rental fee up until, and including, that current day."
        },
        {
            question: "Q: Am I allowed to smoke in the rental vehicle?",
            answer: "A: No. There is no smoking in the vehicle for the drivers or the passengers. There is a $250 Fine if the car comes back with a smoke smell."
        },
        {
            question: "Q: Am I responsible to clean the vehicle?",
            answer: "A: Yes, All Buggy vehicles are cleaned before they are rented out. Please make sure to return the vehicle in the same condition received."
        },
        {
            question: "Q: Do I need to refuel the vehicle?",
            answer: "A: Yes. Buggy sends out all their vehicles with a full tank of gas. All vehicles must be returned with a full tank of gas. If your gas tank was not at full when you received the vehicle, please contact Buggy support."
        },
    ],
    "Maintenance":
    [
        {
            question: "Q: Does Buggy have a mechanic shop?",
            answer: "A: Yes, Buggy has a mechanic shop open from: Sunday 9 AM - 10 PM, Monday - Thursday from 8 AM - 10 PM, Friday from 8 AM 6 PM."
        },
        {
            question: "Q: Where can I go if I need any small maintenance done like oil change, light bulbs etc.?",
            answer: "A: You can go to Buggy Shop located at 389 Empire Blvd Brooklyn, NY 11225, right behind the office where you pick up the car. Buggy covers all maintenance needs for all the drivers that rent from Buggy."
        },
        {
            question: "Q: Am I allowed to get maintenance done at a Non-Buggy mechanic shop?",
            answer: "A: No, However If you need maintenance done on Saturday, when buggy shop is closed, you can go to the mechanic shop, VLC Auto Repair, located at 547 Albany Ave, Brooklyn, NY 11203."
        },
        {
            question: "Q: Will Buggy reimburse me for fixing a mechanical issue with the vehicle?",
            answer: "A: Buggy does not offer reimbursement for most mechanical issues, however some basic maintenance will be covered. Please ask a Buggy Representative to inquire if a specific maintenance fix will be covered or not, and up to what amount."
        },
        {
            question: "Q: What should I do if I get a flat tire?",
            answer: "A: If a driver gets a flat tire, he can change the tire with the spare tire he may find in the trunk. If a spare is not found, Buggy offers 24/7 roadside assistance for all kinds of emergencies. Buggy will have the car towed to our tire shop and Buggy’s customer support will let you driver know when the car is ready for pickup."
        },
        {
            question: "Q: What should I do if my car breaks down?",
            answer: "A:  Buggy offers 24/7 roadside assistance for all kinds of emergencies as mentioned. Therefore, in any case of breakdown or if your car doesn’t start for some reason, please contact Buggy support as soon as possible, so we can have the car towed to Buggy shop and fix the issue. Once that's done Buggy support will let the driver know when the car is ready for pickup."
        }
    ],
    "Accidents":
    [
        {
            question: "Q: What should i do if i have a accident?",
            answer: "A: You must call the police right away and have them report the accident. After you called the police, please contact Buggy support and inform them about the accident. They will guide you to the next step."
        },
        {
            question: "Q: What options are available for insurance?",
            answer: "A: Your Lease Agreement with the Company, includes a insurance policy with a deductible of $1,000. Which means that in the case of an accident you must pay up to $1,000 per occurrence, which will go towards repairing the damages to the vehicle. Our Company now offers an option to reduce the deductible from $1,000 to only $99.00 per occurrence for a weekly payment of $25.00. Please contact us about this, if interested!"
        },
        {
            question: "Q: How do I sign up to pay only a 99 deductible and how does it work?",
            answer: "A: You can contact Buggy if you are interested, and you will be provided with all of the details."
        },
        {
            question: "Q: What happens if I am not at fault for the accident?",
            answer: "A: Buggy will always try to open a insurance claim, if you are found not at fault in the police report. If Buggy is able to close the insurance claim and get liability payments for the damage on the vehicle, Buggy will refund the deductible paid by the driver, to the driver. The driver will still be required to pay towards the damages to Buggy, until the insurance claim is closed."
        },
        {
            question: "Q: What if my vehicle gets damaged and for whatever reason I could not get a police report?",
            answer: "A: If you could not get a police report, Buggy will not be able to open a insurance claim for the driver, and the driver will be liable for all the damages found on the vehicle whether at fault or not."
        }
    ],
    "Tickets and Tolls":
    [
        {
            question: "Q: How do I purchase an E-ZPass?",
            answer: "A: You can purchase an E-ZPass at your local DMV, or at an E-ZPass Office located closest to you."
        },
        {
            question: "Q: How do I set up my E-ZPass?",
            answer: "A: When you purchase an E-ZPass, all of the directions are written in the provided manual. However, you can always ask the representative at the purchased location to help set up your account. You can also come to the Buggy office location, and a representative will be happy to help you setup your account."
        },
        {
            question: "Q: How do I pay for my tolls if they did not read correctly while going through the toll booth and my E-ZPass was not charged?",
            answer: "A: If your E-ZPass was not charged for any reason while going through the toll booth, Buggy will pay for those tolls on your behalf, and then then add those tolls to your Buggy account balance with an additional $5 fee for each toll."
        },
        {
            question: "Q: Why is my E-ZPass not reading correctly?",
            answer: "A: There are a few reasons that your E-ZPass is not reading correctly. If you notice that your E-ZPass is not reading correctly, make sure to check the following: Make sure your transponder is located right behind the rearview mirror. Make sure you are not speeding through the toll lanes. Make sure that you always have enough money on your E-ZPass at all times. We highly recommend that you get Auto-Replenishment set up on your E-ZPass, and have it connected to a valid bank account. This way your E-ZPass will automatically charge your card to ensure that you always have enough money before your balance gets too low."
        },
        {
            question: "Q: What happens if I receive a traffic ticket (ie; camera tickets for parking, red light, speeding, bus lanes, etc)?",
            answer: "A: For parking and camera violations, Buggy will attempt to transfer the ticket liability to the driver's name and charge the driver a $10 processing fee. Buggy will NOT be paying for the ticket, each driver is responsible to pay for each of their tickets. However, If we are unable to transfer liability to the driver's name, Buggy will give the driver 72 hours to take care of the ticket after which Buggy pay the ticket on your behalf and charge the driver the ticket cost and a $10 processing fee."
        },
        {
            question: "Q: How do I go about paying for a ticket?",
            answer: "A: To pay for a ticket online go to: https://secure24.ipayment.com/NYCPayments/nycbookmark_1.htm, and type in your violation number. You can also go to your local DMV to pay for your ticket."
        },
        {
            question: "Q: How do I dispute a ticket if I was not at fault?",
            answer: "A: You can go to you local DMV to dispute your ticket. You can also download the NYC Parking Pay or Dispute App, or you can download the WinIt App and follow the directions to dispute your ticket."
        }
    ],
    "Remote Cash":
    [
        {
            question: "Q: What is Remote Cash?",
            answer: "A: You can now make Cash payments for your weekly rent remotely, at your local 7/11, Dollar General, CVS, Family Dollar, Speedway, and more! For only a $2 fee per transaction, you can save a lot of time and gas, without having to come all the way to the office!"
        },
        {
            question: "Q: How does Remote Cash work? ",
            answer: "A: We will be sending you a link with a bar code which you can use every time you make payment. You will be able to go to any participating stores closest to you with this bar code. Once at the store, tell the cashier that you would like to make a bill payment. You will have the Cashier scan the bar code on your phone, give the cashier your rental payment, plus a $2 fee, and they will give you a physical receipt. They will then be able to transfer the funds from their store, directly to our bank account, where it will then be applied to your balance at Buggy and your rent will be paid!"
        },
        {
            question: "Q: How do I get the Bar-Code link?",
            answer: "A: Please send Buggy a text message requesting the link so that we can send it to you. You can use the same link every time!"
        },
        {
            question: "Q: The cashier did not understand what Remote Cash was. What should I do?",
            answer: "A: This program is still very new, so although all of these stores should already be trained to understand how this program works, there is a possibility that you may come across a cashier who may not be very familiar with this. Try telling them you would like to make a bill payment with Cash,  or  you can mention that you are making a Vanilla Direct Pay bill payment.  You can always ask for a different cashier to help. Otherwise, try to walk the cashier through it. Tell them that by scanning the bar-code on your phone, you can give them a cash payment to send to your rental company. "
        },
        {
            question: "Q: I tried to troubleshoot and walk the cashier through the process but he still does not understand what I am talking about. What should I do now?",
            answer: "A: The first thing you can do is try going to a different location. There are many store locations available, with cashiers that will be more trained in this program. Once you find a location that works, you can continue to go back on a weekly basis to pay your rent. How convenient! "
        },
        {
            question: "Q: I tried everything, and it still doesn’t seem to work for me. Is there anything else I can do?",
            answer: "A: Yes! You can always contact our Customer Support Team at any time, by calling in or texting us, and one of our trained representatives will be sure to help you out."
        }
    ],
};

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeTab: 'Applications',
          showFaq:null
        };
    }

    toggleFaq(question) {
        if(this.state.showFaq===question){
            question=null;
        }
        this.setState({showFaq:question});
    }
    changeTab(tab) {
        this.setState({activeTab:tab});
    }


  render() {
    return (
        <Row className="faqView">
            <Col xs={12}>
                <Nav tabs>
                    {Object.keys(listOfFaqs).map(faqTitle => 
                        {
                            return (
                            <NavItem key={faqTitle}> 
                                <NavLink className={this.state.activeTab===faqTitle?"active":"inactive"} onClick={() => this.changeTab(faqTitle)}>
                                    {faqTitle.toUpperCase()}
                                </NavLink>
                            </NavItem> 
                            );
                        })
                    }
                </Nav>
            </Col>
            <Col xs={12}>
                <TabContent activeTab={this.state.activeTab}>
                {Object.keys(listOfFaqs).map(faqTitle => 
                    listOfFaqs[faqTitle].map(faq => 
                        {
                            return (
                            <TabPane tabId={faqTitle} key={faq.question}>
                                <Row>
                                    <Col key={faq.question} xs="12" sm={{size:7}} className="faqBody">
                                        <Button color="secondary" className="text-left" onClick={()=>this.toggleFaq(faq.question)} size="md" block>{faq.question}</Button>
                                        <Collapse isOpen={this.state.showFaq===faq.question}>
                                            <Card>
                                                <CardBody>{faq.answer}</CardBody>
                                            </Card>
                                        </Collapse>
                                    </Col>
                                </Row>
                            </TabPane>
                            );
                        }
                    )
                )
                }
                </TabContent>
            </Col>
        </Row>
        )}
}
export default Faq