import React, {Component} from 'react';
import {Row, Col } from 'reactstrap';
import CarListView from '../views/CarListView';


class BookCar extends Component {
    constructor(props) {
        super(props);
        let input = {paymentMethod:"Payfare",preferredBase:"Uber",depositPaymentMethod:"0183"}
        this.state = {
            input:input
        }
    }
    updateInput = (e) => {
        let input = this.state.input;
        input[e.target.name] = e.target.value;
        this.setState({input:input}); 
    }
  render() {
    return (
        <Row className="pickupForm">
            <Col xs={12} sm={{ size: 6,offset:3}}>
                <CarListView/>
            </Col>
        </Row>
        )}
}
export default BookCar