import React, {Component} from 'react';
import { Row, Col } from 'reactstrap';


class TLCNotice extends Component {      
  render() {
    return (
        <Row className="wavView">
        <Col xs={12} sm={{ size: 8,offset:2}}>
            <h2 className="mainTitle">TLC Notice</h2>
            <p>Hello,<br/>Due to the recent market cap with TLC, many vehicles due for a renewal inspection with the Taxi and Limousine Commission have received inspection dates only past their current expiration date. Uber and TLC have decided to NOT block those vehicles from driving and is offering a 30 days extension to go to the renewal inspection but, since you have an expiration date set in your Uber app, you must visit an Uber expert as soon as possible and have them adjust the expiration date so Uber won't block you from driving. If you need proof to show your vehicle is active you can visit the TLC database and just type in your plate number.</p>
            <p>Please note this has been confirmed only by TLC and Uber. If you work for any other companies feel free to reach out to them and confirm if they will be honoring it. Also, Buggy will not be offering compensation if you're not able to work for any amount of time if action isn't taken.</p>
            <p>Thank you.</p>
        </Col>
    </Row>  
        )}
}
export default TLCNotice