import React from 'react';
import {Row,Col, Nav, NavItem, NavLink } from 'reactstrap';
import './Footer.css';

export default class Example extends React.Component {
  render() {
    return (
        <Row className="mainFooter">
            <Col  xs="12" sm="3">
                <Nav vertical>
                    <h5>NEW DRIVERS</h5>
                    <NavItem>
                        <NavLink href="/">Apply Now</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/pricing">Pricing</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/wav">WAV Rental</NavLink>
                    </NavItem>
                </Nav>
            </Col>
            <Col  xs="12" sm="3">
                <Nav vertical>
                    <h5>EXISTING DRIVER</h5>
                    <NavItem>
                        <NavLink href="/policies">Policies</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/faq">Faq</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="/tlcnotice">TLC Inspection Notice</NavLink>
                    </NavItem>
                </Nav>
            </Col>
            <Col  xs="12" sm="3">
                <Nav vertical>
                    <h5>CONTACT US</h5>
                    <NavItem>
                        <NavLink href="tel:1-347-334-6317">347 334 6317</NavLink>
                        <NavLink href="mailto:applications@joinbuggy.com?Subject=Hello%Buggy" target="_top">applications@joinbuggy.com</NavLink>
                    </NavItem>
                    <NavItem>
                        <p>445 Empire Blvd<br/>
                        Brooklyn, NY, 11225
                        </p>
                    </NavItem>
                </Nav>
            </Col>
            {/* <Col  xs="12" sm="3">
                <Nav vertical>
                <h5>HOURS</h5>
                <NavItem>
                    <ul><li>9-5</li><li>9-7</li></ul>
                </NavItem>
                </Nav>
            </Col> */}
        </Row>
    );
  }
}