import React, {Component} from 'react';
import { Row, Col, Media } from 'reactstrap';
import './wav.css';
import Sienna from '../images/sienna_2018.png';


class WavRental extends Component {

  render() {
    return (
            <Row className="wavView">
                <Col xs={12} sm={{ size: 8,offset:2}}>
                    <h2 className="mainTitle">INTRODUCING 2018 WAV VEHICLES</h2>
                    <div>
                        <Media>
                            <Media href="#">
                                <Media object src={Sienna} width="100%" className="textCenter" alt="Buggy TLC WAV Rental Car"/>
                            </Media>
                        </Media>
                    </div>
                    <p>With rent as low as $299 (with incentives) our brand new 2018 Toyota Sienna’s are a sure way to earn money on your own schedule.</p>
                    <p>These vehicles are considered UBER XL, and are WAV (Wheelchair Accessible Vehicles).</p>
                    <h6>Rent a WAV vehicle and qualify for:</h6>
                    <ul>
                        <li>Up to $350 towards your weekly rental.</li>
                        <li>Additional $15 per WAV trip.</li>
                        <li>Increased trip incentives, even for non-WAV trips! (Coming soon from Uber)</li>
                    </ul>
                    <h6>What you need to qualify from Uber:</h6>
                    <ul>
                        <li>Get $350 if you complete 50 different rides in a week in the five boroughs.</li>
                        <li>$15 extra for every WAV trip no matter what.</li>
                        <li>The $350 starts when you pick up the car any time from Mon-Friday by 12pm.</li>
                    </ul>
                    <h6>Text 'WAV SPECIAL' to 3473346317 so we can reserve you one ASAP.</h6>
                    <h6>ABOUT The 2018 Toyota Sienna minivan.</h6>
                    <p>Ranked from the top 5 in Minivans, the 2018 Toyota Sienna has all the hallmarks of the modern minivan: a spacious interior, advanced active safety features, and a powerful, efficient powertrain. Its V-6 and eight-speed automatic can be paired with class-exclusive all-wheel drive; front-wheel-drive is standard. Standard safety tech includes automated emergency braking, lane-keeping assist, and more.
                    </p>
                    <p>MPG up 19 City / 27 Highway, Horsepower of 296.</p>
                    <h6>OPERATING WAV VEHICLES</h6>
                </Col>
            </Row>
        )}
}
export default WavRental