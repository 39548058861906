import React, {Component} from 'react';
import { Row, Col, Media } from 'reactstrap';
import WeekendVehicle from '../images/weekend_rental.png';


class WeekendRental extends Component {
   
  render() {
    return (
        <Row className="wavView">
            <Col xs={12} sm={{ size: 8,offset:2}}>
                <h2 className="mainTitle">Weekend Rental</h2>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={WeekendVehicle} width="100%" className="textCenter" alt="Buggy TLC Weekend Rental Car"/>
                        </Media>
                    </Media>
                </div>
                <p>Pickup a car anytime on Friday and have the car returned by 10 am Monday morning.<br/>
                If you wish to extend your rental, we can offer you a plan to pay your rent daily.</p>
                <h6>TERMS & CONDITIONS:</h6>
                <ul>
                    <li>All Payments must be made up front</li>
                    <li>You must pay a $199 deposit. You will get it back within 30 days from the pick up date.</li>
                    <li>You MUST have an active EZ-Pass in the car at all times.</li>
                </ul>
                <p>Please note that the price will NOT be reduced or discounted if you choose to return the car early. </p>
                <h6>Other Info:</h6>
                <ul>
                    <li>If the car is returned after 10 AM there is a late return fee of $100.</li>
                    <li>The car has to be returned clean inside and out (as you got it) or there will be a $35 cleaning fee.</li>
                    <li>If the car is returned missing gas there is a $20 gas charge, per quarter.</li>
                    <li>Please return the car in the same condition it was given to you, so it is immediately ready for the next driver.</li>
                </ul>
            </Col>
        </Row>
        )}
}
export default WeekendRental