import React, {Component} from 'react';
import './pickup_form.css';
import { Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';


class PickupForm extends Component {
    constructor(props) {
        super(props);
        let input = {paymentMethod:"Payfare",preferredBase:"Uber",depositPaymentMethod:"0183"}
        this.state = {
            input:input
        }
    }
    updateInput = (e) => {
        let input = this.state.input;
        input[e.target.name] = e.target.value;
        this.setState({input:input}); 
    }
  render() {
    return (
        <Row className="pickupForm">
            <Col xs={12} sm={{ size: 4,offset:4}}>
                <h2 className="mainTitle">PICK UP FORM</h2>
                <p className="text-center">Please complete this form to complete your pickup.</p>
                <Form>
                    <FormGroup>
                        <Label for="paymentMethod">How would you like to pay your rent when due</Label>
                        <Input type="select" name="paymentMethod" id="paymentMethod">
                            <option>Payfare</option>
                            <option>Cash</option>
                            <option>Card</option>
                            <option>Remote Cash</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="preferredBase">Which company are you planning on driving for</Label>
                        <Input type="select" name="preferredBase" id="preferredBase">
                            <option>Uber</option>
                            <option>Lyft</option>
                            <option>Via</option>
                            <option>Other</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="initialPaymentMethod">You are required to pay a deposit of $399 and you have a balance of $49. How would you like to pay?</Label>
                        <Input type="select" name="initialPaymentMethod" id="initialPaymentMethod">
                            <option>0183</option>
                            <option>0123</option>
                            <option>8965</option>
                        </Input>
                    </FormGroup>
                    <Button onClick={this.submitApplication}>Submit</Button>
                </Form>
            </Col>
        </Row>
        )}
}
export default PickupForm