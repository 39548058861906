import React from 'react';
import Modal from './Modal';
import Button from './Button';
import Input from './Input';
import { Select, SelectItem } from './Select';


export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        let input = {};
        this.state = {
            input:input,
            display: true,
            open:true
        }
    }
    submitForm = () => {
        // console.log("submitted");
    };
    updateInput = (e) => {
        let input = this.state.input;
        input[e.target.name] = e.target.value;
        this.setState({input:input}); 
    }
    render() {
        return (
        <Modal open={this.state.open} showLogo={true} title="Contact form">
            <Select title={"What are you contacting us about"} name="contactReason" value={this.state.input.contactReason} onChange={this.updateInput}>
                <SelectItem value="Report Issue"/>
                <SelectItem value="Booking Inquiry"/>
                <SelectItem value="Payment Inquiry"/>
            </Select>
            <Input onBlur={this.updateInput} required={true} errorMessage="Please explain you reason here." name="details" label="Details" fullWidth={true} placeholder="Please explain you reason here."/>
            <Button onClick={this.submitForm}>Submit</Button>&nbsp;&nbsp;
            <div style={{"display":"inlineBlock","float":"right"}}><Button  onClick={this.props.handleClose} color="cancel">CLOSE</Button></div>
        </Modal>
        )
    }
}