import React from 'react';
import Modal from './Modal';
import './ApplicationForm.css';
import { Select, SelectItem } from './Select';
import { Input, Label, FormGroup, Form, CustomInput, Button, Progress, Alert } from 'reactstrap';
import ImageTools from './ImageTools';

const visionUrl = "https://vision.googleapis.com/v1/images:annotate?key=AIzaSyAtHcBnpaJkrNpxW2TEwoy4-3tjYloJ3Rk";
const referralOptions = ["Google","Friend","Via","Uber","Move Around","Nabil","Other"];
const pickupLocations = {"SPRITZER":"BROOKLYN: 445 Empire Blvd, Brooklyn, NY, 11225","BRONX":"BRONX: 691 Burke Ave, Bronx, NY 10467."}
// const buggyApplicationUrl = "https://c3a39bd2.ngrok.io/new_application/"
const buggyApplicationUrl = "https://buggy-174216.appspot.com/new_application/";
const zapierBackupUrl = "https://hooks.zapier.com/hooks/catch/1840631/omuk40z/";

export default class ApplicationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referred_by:"",
            pick_up_location:Object.keys(pickupLocations)[0],
            source:null,
            first_name:"",last_name:"",phone:"",email:"",dmv_license:"",tlc_license:"",referred_by_contact:"",type_of_car_wanted:"",
            documents:{},
            required:["first_name","last_name","phone","email","dmv_license","tlc_license"],
            formValid:false,
            page:1,
            error:null,
            loading:false,
            licenseImageProgressMessage:null,
            hasMoreDocs:"No"
        }
    }
    submitApplication = (e) => {
        e.preventDefault();
        try{
            this.setState({loading:true})
            let formData = new FormData(e.target);
            let first_name = this.state.first_name;
            let last_name = this.state.last_name;
            let email = this.state.email;
            let dmv_license= this.state.dmv_license;
            let tlc_license = this.state.tlc_license;
            let documents = this.state.documents;
            if(documents&&Object.keys(documents).length > 0){
                Object.keys(documents).map((item,i)=>
                    {
                        formData.append('documents['+item+']', documents[item])
                    })
            }
            if(first_name && last_name && email && dmv_license && tlc_license){
                fetch(buggyApplicationUrl, {
                    method: 'POST',
                    body: formData
                }).then((response) => {
                    this.setState({page:3,error:null,loading:false})
                }).catch();
                fetch(zapierBackupUrl, {
                    method: 'POST',
                    body: formData
                }).then((response) => {
                }).catch();
                this.setState({page:3,error:null,loading:false})
            }
            
        }catch(err){
            // this.setState({error:"An error occured during app submission. Please retry.",loading:false})
        }
    }; 
    updateInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(e.target.type==="number"){
            if(isNaN(parseFloat(value))){
                value = 0
            }else{
                value = parseFloat(value)
            }
        }
        this.setState({
            [name]: value
          }, () => {
            this.validateApplicationForm()
          })
        
    }
    validateApplicationForm = () => {
        let requiredArray = this.state.required;
        let currentState = this.state;
        let inValid = [];
        requiredArray.map(required =>{
            if(required in currentState && currentState[required] === "" || currentState[required] === undefined || currentState[required] === null){
                inValid.push(required);
            }
            if(required==="email" && required in currentState && currentState[required]){
                let emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if(!emailRegex.test(String(currentState[required]).toLowerCase())){
                    inValid.push(required);
                }else{

                }
            }
            if(required==="phone" && required in currentState && currentState[required]){
                let phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                if(!phoneRegex.test(String(currentState[required]).toLowerCase())){
                    inValid.push(required);
                }
            }
        })
        if(inValid && inValid.length > 0){
            inValid = inValid.map(item=>item.toUpperCase());
            inValid = inValid.join(", ");
            this.setState({formValid:false,error:null})
        }else{
            this.setState({formValid:true,error:null})
        }
    }
    verifyTlcLicense = async (tlcLicenseNumber) => {
        //Verify TLC License from the NYC Open Data Website. Returns a single array containing license info object
        let nycOpenDataUrl = "https://data.cityofnewyork.us/resource/xjfq-wh2d.json?license_number="+tlcLicenseNumber;
        let appToken = "5WBDkAQLj244SKLuDJmXDVDhT";
        let response = null;
        try {
            const res = await fetch(nycOpenDataUrl, {
                method: 'GET',
                data: {
                    "$limit": 1,
                    "$$app_token": appToken
                }
            });
            const data = await res.json();
            //Api returns an array
            if (data && data[0] && data[0].name) {
                let name = data[0].name;
                name = name.split(",");
                if (name && name.length > 0) {
                    response = {"last_name":name[0],"first_name":name[1]};
                }
            }
        }
        catch (err) {
            // console.log(err)
        }
        return response;
    }
    allowedFileType = (fileType) => {
        const allowed = ["jpg","jpeg","png"]
        let extension = "";
        if(fileType){
            let split_ = fileType.split("/");
            if(split_.length > 1 && split_[1]){
                extension = split_[1].toLowerCase()
            }
        }
        return allowed.includes(extension);
    }
    
    updateFile  = (e) => {
        let _this = this;
        let filename = e.target.name;
        if(this.allowedFileType(e.target.files[0].type)){
            ImageTools.resize(e.target.files[0], {width: 500, height: 500 }, function(resizedImage, didItResize) {
                var reader = new FileReader();
                reader.readAsDataURL(resizedImage);
                reader.onload = function(e) {
                    let imageInBase64 = reader.result
                    imageInBase64 = imageInBase64.substring(imageInBase64.indexOf(',') + 1);
                    let documents = _this.state.documents
                    documents[filename] = imageInBase64;
                    _this.setState({documents:documents,error:null});
                };
            })
        }else{
            const error = "File type not accepted. Please upload a .jpg,.jpeg or .png file";
            let documents = _this.state.documents
            documents[filename] = null;
            _this.setState({error:error,documents:documents});

        }
        
    }
    processDocument = async (document,documentName) => {
        if(document){
            try {
                this.setState({licenseImageProgressMessage:"Please wait while we read and verify your "+documentName})
                let visionRequest = {
                    "requests": [
                    {
                        "image": {
                        "content": document
                        },
                        "features": [
                        {
                            "type": "TEXT_DETECTION",
                            "maxResults": 4
                        }
                        ]
                    }
                    ]
                }
                const response = await fetch(visionUrl, {
                    method: 'POST',
                    headers: {'Accept': 'application/json, text/plain, */*',},  
                    body: JSON.stringify(visionRequest)
                });
                const responseData = await response.json();
                if(responseData && responseData.responses[0] && responseData.responses[0].fullTextAnnotation && responseData.responses[0].fullTextAnnotation.text){
                    let fullText = responseData.responses[0].fullTextAnnotation.text;
                    if(documentName==="TLC License"){
                        if(fullText && fullText.toLowerCase().includes("license")){//NYC TLC License has "LICENSE" text contained. Use to verify that a tlc license was infact returned
                            let tlcLicensefromImage = fullText.split('NUMBER\n').pop().split('\n')[0];   
                            tlcLicensefromImage = tlcLicensefromImage.replace(/ /g,''); 
                            const verifyLicense = await this.verifyTlcLicense(tlcLicensefromImage);
                            if(verifyLicense && "last_name" in verifyLicense && "first_name" in verifyLicense){
                                this.setState({page:2,first_name:verifyLicense["first_name"],last_name:verifyLicense["last_name"],tlc_license:tlcLicensefromImage,licenseImageProgressMessage:null,error:null})
                            }else{
                                this.setState({page:2,error:"License could not be validated. Please enter your license number",licenseImageProgressMessage:null})
                            }
                        }else{
                            this.setState({page:2,error:"License could not be validated. Please enter your license number",licenseImageProgressMessage:null})
                        }
                    }
                }else{
                    this.setState({page:2,error:"License could not be validated. Please enter your license number",licenseImageProgressMessage:null})
                }
            } catch (error) {
                this.setState({page:2,error:"License could not be validated. Please enter your license number",licenseImageProgressMessage:null})                
            }
        }
    }
    imageUploadHandler = (e) => {
        //Validate TLC License Only
        if(this.state.documents["tlc_license_image"]){
            this.processDocument(this.state.documents["tlc_license_image"],"TLC License").then(
                // this.setState({page:2})
            )
        }
    }
    componentDidMount = () =>{
        if(!this.state.source){
            const url = window.location && window.location.href ? window.location.href:window.location;
            this.setState({source:url})
        }
    }
    render() {
        
        return (
        <div className="applicationForm">
            <Modal open={this.props.open} showLogo={true} title="Application form">
                <Alert color="info">For more information, <a href="tel:+13473346317">Call us: 347 334 6317</a></Alert><br/>
                {this.state.error &&<Alert color="danger">{this.state.error}</Alert>}
                {this.state.page === 1 &&
                    <div>
                        {this.state.licenseImageProgressMessage &&<Progress bar animated value="100\">{this.state.licenseImageProgressMessage}</Progress>}
                        <FormGroup className="licenseImagesUpload">
                            <Label for="dmv_license_image">Please upload your DMV License Picture</Label>
                            <CustomInput type="file" onChange={this.updateFile} id="dmv_license_image" name="dmv_license_image" label="DMV License Image" />
                            {/* {this.state.dmv_license_image &&<img src={"data:image/jpeg;base64,"+this.state.dmv_license_image} width="100"/>} */}
                            {this.state.documents["dmv_license_image"]&&<p><small>DMV License added</small></p>}
                        </FormGroup>
                        <FormGroup className="licenseImagesUpload">
                            <Label for="tlc_license_image">Please upload your TLC License Picture</Label>
                            <CustomInput type="file" onChange={this.updateFile} id="tlc_license_image" name="tlc_license_image" label="TLC License Image" />
                            {this.state.documents["tlc_license_image"]&&<p><small>TLC License added</small></p>}
                        </FormGroup>
                        <Button style={{"display":"inlineBlock","marginRight":"5px"}} onClick={()=>this.setState({page:2})} color="cancel">
                            {this.state.error?"Continue":"Dont have it with me"}
                        </Button>
                        <Button style={{"display":"inlineBlock","marginRight":"5px"}} disabled={(!this.state.documents["dmv_license_image"] || !this.state.documents["tlc_license_image"])?true:false} onClick={this.imageUploadHandler} color="primary">
                            Upload Documents
                        </Button>
                    </div>
                }

                {this.state.page === 2 &&
                    <div>
                        <Form name="applicationForm" onSubmit={this.submitApplication}>
                            <FormGroup>
                                <Label for="tlc_license">TLC License</Label>
                                <Input type="text" name="tlc_license" onChange={this.updateInput} placeholder="Enter your TLC License number" value={this.state.tlc_license}/>
                                <Input type="hidden" name="source" value={this.state.source}/>
                                <Input type="hidden" name="dmv_license_image" value={this.state.documents["dmv_license_image"]}/>
                                <Input type="hidden" name="tlc_license_image" value={this.state.documents["tlc_license_image"]}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="first_name">First Name</Label>
                                <Input type="text" name="first_name" onChange={this.updateInput} placeholder="Enter your first name" value={this.state.first_name}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="last_name">Last Name</Label>
                                <Input type="text" name="last_name" onChange={this.updateInput} placeholder="Enter your last name" value={this.state.last_name}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="dmv_license">DMV License</Label>
                                <Input type="text" name="dmv_license" onChange={this.updateInput} placeholder="Enter your Driver License number" value={this.state.dmv_license}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" onChange={this.updateInput} placeholder="Enter your email address" value={this.state.email}
                                size="30" required/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="phone">Phone (e.g +19171231234)</Label>
                                <Input type="tel" name="phone" onChange={this.updateInput} placeholder="Enter your phone number" value={this.state.phone}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="type_of_car_wanted">What type of car would you like? (optional)</Label>
                                <Input type="text" name="type_of_car_wanted" onChange={this.updateInput} placeholder="What type of car would you like?" value={this.state.type_of_car_wanted}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="pick_up_location">Where would you like to pickup your car?</Label>
                                <Input type="select" name="pick_up_location" id="pick_up_location" onChange={this.updateInput} placeholder="Where would you like to pickup your car">
                                    {Object.keys(pickupLocations).map((item,i)=>
                                        <option className="option" value={item}>{pickupLocations[item]}</option>
                                    )}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="referred_by">How did you hear about Buggy?</Label>
                                <Input type="select" name="referred_by" id="referred_by" onChange={this.updateInput} placeholder="How did you hear about Buggy?">
                                    {referralOptions.map((item,i)=>
                                        <option className="option" value={item}>{item}</option>
                                    )}
                                </Input>
                            </FormGroup>
                            {this.state.referred_by==="Friend" &&
                                <FormGroup>
                                    <Input name="referred_by_contact" placeholder="Enter your friend's number. They're eligible for a $50 referral bonus" type="phone"/>
                                </FormGroup>
                            }
                            <FormGroup>
                                <Label for="hasMoreDocs">Do you have other documents that can help us with your insurance approval process? e.g Foreign License (with translation), Driving record (MVR/Abstract) or Police Report - from the past 3 years.</Label>
                                <Input type="select" name="hasMoreDocs" value={this.state.hasMoreDocs} id="hasMoreDocs" onChange={this.updateInput} placeholder="Do you have other documents that can help us with your insurance approval process? e.g Foreign License (with translation), Driving record (MVR/Abstract) or Police Report - from the past 3 years.">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Input>
                            </FormGroup>
                            {this.state.hasMoreDocs === "Yes" &&
                                <FormGroup className="">
                                    <Label for="other_doc_a">You can upload up to 3 documents more</Label>
                                    <CustomInput type="file" onChange={this.updateFile} id="other_doc_a" name="other_doc_a" label="Document" />
                                    {this.state.documents["other_doc_a"]&&<p><small>1st document added</small></p>}
                                    {/* <Label for="otherDocB">Document 2</Label> */}
                                    <CustomInput type="file" onChange={this.updateFile} id="other_doc_b" name="other_doc_b" label="Document" />
                                    {this.state.documents["other_doc_b"] &&<p><small>2nd document added</small></p>}
                                    {/* <Label for="otherDocC">Document 3</Label> */}
                                    <CustomInput type="file" onChange={this.updateFile} id="other_doc_c" name="other_doc_c" label="Document" />
                                    {this.state.documents["other_doc_c"]&&<p><small>3rd document added</small></p>}
                                </FormGroup>
                            }
                            {this.state.error &&<Alert color="danger">{this.state.error}</Alert>}
                            {this.state.loading && <Progress bar animated value="90\">Your application is being submitted, please wait...</Progress>}
                            {this.state.formValid===true?
                                <input disabled={this.state.loading} type="submit" className="secButton"/>:
                                <Button className="secButton" onClick={()=>this.setState({error:"Please fill the required fields with valid values."})}>Submit</Button>
                            }
                            
                            <Button color="cancel" onClick={()=>this.setState({page:1})}>Back</Button>
                            <div style={{"display":"inlineBlock","float":"right"}}>
                                {/* <a href="https://www.joinbuggy.com">CLOSE</a> */}
                                <a href="tel:+13473346317">Call us: 347 334 6317</a>
                            </div>
                        </Form>
                    </div>
                }
                {this.state.page === 3 &&
                    <div style={{"textAlign":"center"}}>
                        <div style={{"display":"inlineBlock","padding":"0px 0px 10px 0 "}}>
                            <img alt="Completed successfully" width={"80px"} src={require("../images/completed_success.svg")}/>
                        </div>
                        <Alert color="success">{"Application Completed Successfully."}</Alert>
                        <p>Our reps will contact you in a few minutes to inform you about the status of your application. Thank you.</p>
                        <p>If you have any questions, please contact us at: <strong>info@joinbuggy.com</strong></p>
                        {/* <a href="https://www.joinbuggy.com">CLOSE</a> */}
                        <a href="tel:+13473346317">Call us: 347 334 6317</a>
                    </div>
                }
            </Modal>
        </div>
        )
    }
}

