import React from 'react';
import Modal from './Modal';
import Button from './Button';
import './BookCarConfirmation.css';
import {Link} from 'react-router-dom'


export default class BookCarConfirmation extends React.Component {
    constructor(props) {
        super(props);
        let input = {referredBy:"Google"};
        this.state = {
            input:input,
            display: true,
            open:true,
            page:"stepOne"
        }
    }
    submitApplication = () => {
        this.setState({page:"stepThree"})
    };
    showStepTwo=()=>{
        this.setState({page:"stepTwo"}); 
    }
    showStepOne=()=>{
        this.setState({page:"stepOne"}); 
    }
    updateInput = (e) => {
        let input = this.state.input;
        input[e.target.name] = e.target.value;
        this.setState({input:input}); 
    }
    render() {
        return (
        this.props.carId ?
        <Modal open={this.state.open} showLogo={true} title="Booking Confirmation">
            <div className={this.state.page==="stepOne"?"page stepOne bookCarConfirmation":"page stepOne hidePage bookCarConfirmation"}>
            <p>You're about to reserve <strong>Car {this.props.carId}</strong>. Is this correct?</p>
                <Button onClick={this.showStepTwo}>Yes</Button>&nbsp;&nbsp;
                <div style={{"display":"inlineBlock","float":"right"}}><Button  onClick={this.props.handleClose} color="cancel">Cancel</Button></div>
            </div>
            <div className={this.state.page==="stepTwo"?"bookCarConfirmation page stepTwo":"page stepTwo hidePage bookCarConfirmation"}>
            <p>Congratulations. Car {this.props.carId} has been reserved for you to pick up</p>
            <br/><img alt="Completed successfully" width={"100"} src={require("../images/completed_success.svg")}/><br/><br/>
                <a href="/">CLOSE</a>
            </div>
        </Modal>
        :null
        )
    }
}