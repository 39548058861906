// import { InMemoryCache } from 'apollo-cache-inmemory';
// import ApolloClient from 'apollo-client';
// import { BatchHttpLink } from "apollo-link-batch-http";

// const client = new ApolloClient({
//     link: new BatchHttpLink({ 
//       uri: '/graphiql/' }),
//     cache: new InMemoryCache(),
//   });
// export default client;


import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { BatchHttpLink } from "apollo-link-batch-http";

import { HttpLink } from 'apollo-link-http';
import {withClientState} from 'apollo-link-state';
import {ApolloLink} from 'apollo-link';

function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) return parts.pop().split(";").shift();
}

// const client = new ApolloClient({
//     link: new BatchHttpLink({ uri: '/graphiql/',
//     headers: {"X-CSRFToken":  getCookie('csrftoken')},
//     credentials: 'same-origin' }),
//     cache: new InMemoryCache(),
//   });
// export default client;


const cache = new InMemoryCache();

const stateLink = withClientState({
    cache,
    resolvers: {
        Mutation: {
        }
    }
});


const client = new ApolloClient({
  link: ApolloLink.from([stateLink, new HttpLink({ uri: '/graphiql/',
  headers: {"X-CSRFToken":  getCookie('csrftoken')},
  credentials: 'same-origin' })]),
  dataIdFromObject: o => o.id,
  cache: cache,
  defaultOptions:{
      watchQuery: {
          fetchPolicy: 'network-only',
          errorPolicy: 'ignore',
      },
      query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
      }
  }
});
export default client;