import React, { Component } from 'react';
import {Route,Switch} from 'react-router-dom';
import './RoutingInfo.css';
import './App.css';
import {Container} from 'reactstrap';
import DetailView from './views/DetailView';
import Policies from './pages/policies';
import TLCNotice from './pages/tlcnotice';
import WeekendRental from './pages/weekend';
import Pricing from './pages/pricing';
import { compose } from 'react-apollo';
import {withApollo} from "react-apollo/index";
import WavRental from './pages/wav';
import Footer from './components/Footer';
import TopNav from './components/TopNav';
import PickupForm from './pages/pickup_form';
import BookCar from './pages/book_car';
import Faq from './pages/faq';
import { SaveSessionMutation } from './Functions/mutations';
import moment from 'moment-timezone';
import ApplicationForm from './views/ApplicationForm';


const pageTitle = (
  <Switch>
    <Route exact path="/" render={() => "Drivers"}/>
    <Route path="/wav" render={() => "WavRental"}/>
    <Route path="/weekend" render={() => "WeekendRental"}/>
    <Route path="/pricing" render={() => "Pricing"}/>
    <Route path="/policies" render={() => "Policies"}/>
    <Route path="/tlcnotice" render={() => "TLCNotice"}/>
    <Route path="/pickup" render={() => "PickupForm"}/>
    <Route path="/book_car" render={() => "BookCar"}/>
    <Route path="/faq" render={() => "Faq"}/>
  </Switch>
)

class RoutingInfo extends Component {
  constructor(props) {
      super(props);
      let driverInput = {firstName:"",lastName:"",email:"",tlc:"",dmv:"",dmvState:""};
      this.state = {
        open: false,
        selectedIndex: 1,
        logsCount:0,
        driverInput:driverInput,
      }
  }
  handleUpdateSelectedIndex = (e,index) => {
      this.setState({selectedIndex: index});
  };
  updateDriverInput = (e) => {
    let driverInput = this.state.driverInput;
    driverInput[e.target.name] = e.target.value;
    this.setState({driverInput: driverInput}); 
    this.checkMvr();
  };
  checkMvr = (e) => {
    let {firstName,lastName,dmv,tlc,dmvState} = this.state.driverInput;
    if(firstName && lastName && dmv && tlc && dmvState){
      //
    }
  };
  
  render() {
    return (
            <div>
              <Container fluid className="mainContainer">
                <ApplicationForm open={true}/>
                <TopNav/>
                {/* <Switch>
                    <Route exact path="/" component={ApplicationForm}/> */}
                    {/* <Route exact path="/" component={DetailView}/> */}
                    {/* <Route exact path="/wav" component={WavRental}/>
                    <Route path="/weekend" component={WeekendRental}/>
                    <Route path="/pricing" component={Pricing}/>
                    <Route path="/policies" component={Policies}/>
                    <Route path="/tlcnotice" component={TLCNotice}/>
                    <Route path="/pickup" component={PickupForm}/>
                    <Route path="/book_car" component={BookCar}/>
                    <Route path="/faq" component={Faq}/> */}
                {/* </Switch> */}
                <Footer/>
              </Container>
            </div>
    );
  }
}
export default compose(
    withApollo
)(RoutingInfo);
