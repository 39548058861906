import React from 'react';
import './Input.css';

export default class Input extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            showError:false
        }
    }
    
    checkValidity = (e) => {
        if(e.target.value===""){
            if(this.props.required){
                this.setState({showError: true});
            }
        }else{
            this.setState({showError: false});
        }
        this.setState({value: e.target.value});
    };
    render() {
        const {onBlur,onChange} = this.props;
        return <div className={this.state.showError?"error-state":"no-error-state"}>
            {this.state.showError?<label>{this.props.errorMessage?this.props.errorMessage:"Please fix input field"}</label>:
            <label className={this.state.value===""?"hide":"show"}>{this.props.label}</label>}
            <input onChange={(e)=>this.props.onChange&&onChange(e)} onBlur={(e)=>{this.checkValidity(e); this.props.onBlur&&onBlur(e);}} className={this.props.fullWidth?"full-width":"no-full-width"} name={this.props.name} placeholder={this.props.placeholder}/>
            </div>
    }
}