import React from 'react';
import './Select.css';

class Select extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value:props.value,
            showPopUp:false
        }
    }
    showPopUp = (e) => {
        e.preventDefault();
        this.setState({showPopUp:true});
    }
    hidePopUp = (e) => {
        this.setState({showPopUp:false});
    }
    changeValue = (e) => {
        this.setState({value: e.target.value});
    };
    render() {
        return <div className={"select"}>
                <label htmlFor={this.props.name}>{this.props.title}</label>
                <input id={this.props.name} name={this.props.name} value={this.state.value} onClick={this.showPopUp} onChange={this.hidePopUp}/>
                <div className={this.state.showPopUp?"popUp show":"popUp"}>
                {this.props.children.map(item=>
                <div key={item.props.value}>
                    <input onChange={(e)=>{this.changeValue(e);this.props.onChange(e);}} name={this.props.name} id={item.props.value} value={item.props.value} type="radio"/>
                    <label onClick={this.hidePopUp} htmlFor={item.props.value}>{item.props.value}</label>
                </div>
                )}
                </div>
            </div>
    }
}
class SelectItem extends React.Component {
    render() {
      return (
        <input value={this.props.value}/>
      );
    };
  }

export { Select, SelectItem};