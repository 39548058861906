import React from 'react';
import './Modal.css';

export default class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {display: true}
    }
    toggleDisplay = () => {
        let currentState = this.state.display;
        this.setState({display: !currentState});
    };
    render() {
        return <div className={"modal-container "+(this.props.open?"show":"")}>
            <div className="modal-body">
                <header onClick={this.toggleDisplay}>
                {this.props.showLogo&&
                    <><img alt="Buggy Logo" width={"150"} src={require("../images/logo-buggy-color-sec.svg")}/><br/></>
                }
                {this.props.title}
                </header>
                <div className="content">
                    {this.props.children}
                </div>
            </div>
        </div>
    }
}