import React from 'react';
import './CarListView.css';
import BookCarConfirmation from './BookCarConfirmation';

export default class CarListView extends React.Component {
    constructor(props) {
        super(props);
        let input = {};
        this.state = {
            input:input,
            display: true,
            showApplicationForm:false,
            showBookCarConfirmation:false,
            selectedCar:null
        }
    }
    bookCar = (carId) => {
        this.setState({showBookCarConfirmation:true,selectedCar:carId});
    };
    closeBookCarConfirmation = () => {
        this.setState({showBookCarConfirmation:false}); 
    };
    updateInput = (e) => {
        let input = this.state.input;
        input[e.target.name] = e.target.value;
        this.setState({input:input}); 
    }
    handleClose = () => {
        this.closeBookCarConfirmation();
        if(this.props.handleClose){
            this.props.handleClose()
        }
    }
    render() {
        return <div className="carListView">
            {this.state.showBookCarConfirmation &&
                <BookCarConfirmation carId={this.state.selectedCar} handleClose={this.handleClose}/>
            }

          <div className="item">
            <img alt="Buggy TLC Rental Booking Easy Process" src={require('../images/nissan_altima.jpg')} className="img-fluid card"/>
            <header className="title">ID: 1305 - 2017 BK Nissan Sentra</header>
            <p>The 2017 Nissan Sentra is a compact car with top-notch safety scores, impressive fuel economy, and plenty of seating space. MPG: Up to 29 city / 37 highway. Horsepower: 124-188
                <br/><small>Available time</small>
            </p>
            <button onClick={()=>this.bookCar("1305")}>BOOK NOW</button>
          </div>
          <div className="item">
            <img alt="Buggy TLC Rental Booking Easy Process" src={require('../images/nissan_altima.jpg')} className="img-fluid card"/>
            <header className="title">ID: 1206 - 2017 BK Nissan Sentra</header>
            <p>The 2017 Nissan Sentra is a compact car with top-notch safety scores, impressive fuel economy, and plenty of seating space. MPG: Up to 29 city / 37 highway. Horsepower: 124-188
            <br/><small>Available time</small>
            </p>
            <button onClick={()=>this.bookCar("1206")}>BOOK NOW</button>
          </div>
          <div className="item">
            <img alt="Buggy TLC Rental Booking Easy Process" src={require('../images/nissan_altima.jpg')} className="img-fluid card"/>
            <header className="title">ID: 123 - 2017 BK Nissan Sentra</header>
            <p>The 2017 Nissan Sentra is a compact car with top-notch safety scores, impressive fuel economy, and plenty of seating space. MPG: Up to 29 city / 37 highway. Horsepower: 124-188
            <br/><small>Available time</small>
            </p>
            <button onClick={()=>this.bookCar("123")}>BOOK NOW</button>
          </div>
        </div>
    }
}