import gql from 'graphql-tag';

const UpdateMessageMutation = gql`mutation updateMessage($input:UpdateMessageMutationInput!){
    updateMessage(input:$input){
        message
        formErrors
    }
}`;

const RemoveMessageMutation = gql`mutation removeMessage($input:RemoveMessageMutationInput!){
    removeMessage(input:$input){
        formErrors
    }
}`;

const CreateContactInfoMutation = gql`mutation createApplicationEmail($input:CreateContactInfoMutationInput!){
    createContactInfo(input:$input){
        ok
        errors
    }
}`;

const SaveSessionMutation = gql`mutation saveSession($input:SaveSessionMutationInput!){
    saveSession(input:$input){
        ok
        errors
    }
}`;

export {UpdateMessageMutation, RemoveMessageMutation, CreateContactInfoMutation, SaveSessionMutation};