import React, {Component} from 'react';
import './DetailView.css';
import { Row, Col, Media, Collapse, Button, Card, CardBody, Jumbotron } from 'reactstrap';
import Accordion from './Accordion';
import Wave from './Wave';
import ApplicationForm from './ApplicationForm';
import EmailForm from './EmailForm';
import Process from '../images/process.svg';
import NoCommitment from '../images/no-commitment.svg';

const listOfFaqs = [
    {
        question: "What is Buggy?",
        answer: "Buggy is a TLC Car Rental Company. If you have a TLC License and you are looking to drive for Uber, Lyft, Juno, Gett, or other taxi/cab services, Buggy is just for you!"
    },
    {
        question: "Which documents do I need to get into a vehicle?",
        answer: "Make sure to bring your TLC, and DMV License, Social Security, Banking Information, your Credit Card and Debit Card, and money to pay for your Security Deposit."
    },
    {
        question: "Where can I go if I need any maintenance done?",
        answer: "You can go to Buggy Shop located at 389 Empire Blvd Brooklyn, NY 11225, right behind the office where you pick up the car. Buggy covers all maintenance needs for all the drivers that rent from Buggy."
    },
    {
        question: "Which payment methods are available to pay for my bill?",
        answer: "We have a few different methods of payment available. We accept direct payments through Zelle or Quick Pay from participating bank accounts, Cash payments at our office location, or  of course you can pay with your debit or credit card. (Please note, additional fees may apply when paying with your credit or debit card)."
    },
    
  ];
class DetailView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scrollItemPosition:0,
            scrollItemTranslate:0,
            scrollItemHeight:0,
            showApplicationForm:false,
            showFaq:null
        }
    }
    showApplicationForm = () => {
        this.setState({showApplicationForm: true}); 
    };
    closeApplicationForm = () => {
        this.setState({showApplicationForm: false}); 
    };
    toggleFaq(question) {
        if(this.state.showFaq===question){
            question=null;
        }
        this.setState({showFaq:question});
    }
    render() {
    return <Row className="detailView">
            {/* <AllMessagesModal/> */}
            {this.state.showApplicationForm&&
            <ApplicationForm handleClose={this.closeApplicationForm}/>
            }
            <Col xs="12">
                <Jumbotron>
                    <Col xs="12" sm={{ size: 10,offset:1}} className="videoWrapper">
                        <video playsInline controls={false} autoPlay muted loop>
                            <source src={require('../images/video.mp4')} type="video/mp4"/>
                        </video>
                    </Col>
                    <Col xs="12">
                    <h1 className="display-3">Rent with BUGGY,</h1>
                    <h2 className="display-4">earn everywhere.</h2>
                    </Col>
                    <Col xs="12" sm={{ size: 6}}>
                    
                    <p className="lead">Want to driver for Uber, Lyft, Juno, Via or any
                            other ride-sharing service but dont have your
                            vehicle ready?<br/>
                            We're here to help. We oﬀer a wide range of
                            TLC Ready Vehicles at aﬀordable prices for to
                            rent, get on the road and start making money!</p>
                    <hr className="my-2" />
                    <div className="lead">
                        <EmailForm/>
                    </div>
                    </Col>
                </Jumbotron>
                
                <Row>
                    <Col xs="12" sm={{ size: 6}}>
                        
                        
                    </Col>
                </Row>
            </Col>
            
            <Col xs="12" id="how-it-works">
                <Row>
                    <Col xs="12" sm="6" style={{"paddingRight":"10%"}} >
                        <h2><strong>How it works</strong></h2>
                        <h5><strong>1. Apply</strong></h5>
                        <p>Apply easily with your valid DMV License,
                            TLC License and Social Security Number.</p>

                        <h5><strong>2. Reserve a Car</strong></h5>
                        <p>Once approved, you will be provided access
                            to a list of our immediately available cars
                            for you to select from.</p>


                        <h5><strong> 3. Start Driving</strong></h5>
                        <p>Grab the keys to your reserved car and start
                            earning on the road. We provide several
                            convenient method to pay for your rental.
                        </p>
                        <p><br/>
                        <Button  size="md" onClick={this.showApplicationForm}>
                            APPLICATION IS FREE! APPLY NOW
                        </Button>
                        </p>
                    </Col>
                    <Col xs="12" sm="6">
                        <div>
                            <Media>
                                <Media href="#">
                                    <Media object src={Process} width="80%" className="textCenter" alt="Buggy TLC Rental Booking Easy Process" />
                                </Media>
                            </Media>
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col xs="12" className="secondaryBg noCommitment">
                <Row>
                    <Col xs="12" sm="6">
                        <div>
                            <Media>
                                <Media href="#">
                                    <Media object src={NoCommitment} width="100%" className="textCenter" alt="No commitment" />
                                </Media>
                            </Media>
                        </div>
                    </Col>
                    <Col xs="12" sm="6" className="noCommitmentCaption">
                        <h2>No Commitment</h2>
                        <p>
                            1. Pickup you car anytime by booking a car from the link on our website or
                            texting our car assignment team to reserve a car.
                        </p>
                        <p>2. Once you're ready to return your car, simply give us a 5 day return notice to allow for processing.</p>
                        <p>3. On your return date, bring your car to our parking lot and speak to our reps to close your account until you're ready to pickup again.</p>
                    </Col>
                </Row>
            </Col>

            <Col xs="12" id="faq" className="faq text-left">
                <Row>
                    <Col xs="12" className="no-padding">
                        <h2>Frequently Asked Questions</h2>
                    </Col>
                    {listOfFaqs.map(faq => 
                        {
                            return (
                            <Col key={faq.question} xs="12" sm={{size:7}} className="faqBody">
                                <Button color="secondary" className="text-left" onClick={()=>this.toggleFaq(faq.question)} size="md" block>{faq.question}</Button>
                                <Collapse isOpen={this.state.showFaq===faq.question}>
                                    <Card>
                                        <CardBody>{faq.answer}</CardBody>
                                    </Card>
                                </Collapse>
                            </Col>
                            );
                        })
                    }
                    <Col xs="12" className="no-padding">
                        <a href="/faq">Read more...</a>
                    </Col>
                </Row>
            </Col>

            <Col xs="12" className="testimonials">
                <Row>
                    <Col xs="12" className="no-padding text-center">
                        <h2>Testimonials</h2>
                    </Col>
                    <Col xs="12" sm={{size:8, offset:2}}>
                        <Card>
                            <CardBody>
                                <h4  className="text-center"> ESTETYK - UBER DRIVER</h4>
                                <p>"That was easy"  should be  Buggy's slogan. I got brand new 2016 Altima lease to own, from them , its  mine now. Dealing with Buggy over two years now and I can  assure you that it's a solid, professional business. Very fair and accommodating, if there is a way for them to help you, they will. Any issue, they will work with you. Everything is transparent, nothing is hidden there , you'll know everything upfront what you'll get and how things works. Support is always there, any time, very knowledgeable stuff , any issue is solved immediately, no running around.</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Col>
    </Row>
  }
}
export default DetailView