import React, {Component} from 'react';
import { Row, Col, Media } from 'reactstrap';
import './policies.css';
import Requirements from '../images/requirements.png';
import PaymentMethods from '../images/payment_methods.png';
import Insurance from '../images/insurance.png';
import PetsSmoking from '../images/pets_smoking.png';
import Accidents from '../images/accidents.png';
import Tolls from '../images/tolls.png';
import Repairs from '../images/repairs.png';
import Tires from '../images/tires.png';
import TlcRules from '../images/tlc_rules.png';







class Policies extends Component {
  render() {
    return (
        <Row className="policies">
            <Col xs={12}>
                <h2 className="mainTitle">POLICIES</h2>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Requirements} width="100%" className="textCenter" alt="Buggy TLC Policies"/>
                        </Media>
                    </Media>
                </div>
                <h6>Requirements</h6>
                <p>Driver must be 21 years old and  must have the following documentation. A valid driver’s license and a TLC license issued in New York.</p>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={PaymentMethods} width="100%" className="textCenter" alt="Buggy TLC Payments"/>
                        </Media>
                    </Media>
                </div>
                <h6>Payment Methods</h6>
                <p>We accept cash, credit cards, Bank transfers through Zelle or Chase Quick pay. We require a $399 deposit upon pick-up of a vehicle. All subsequent payments will be at the end of your weekly rental period. Any security deposits will be returned 3 weeks from the end of your rental.</p>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Insurance} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>Insurance</h6>
                <p>We will provide you an auto insurance policy that meets TLC Vehicle Insurance Requirements. There is a $1000 deductible that the renter will be held responsible for if any damage is done to one of our vehicles during the rental period.</p>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={PetsSmoking} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>Pets and Smoking</h6>
                <p>Please do not carry dogs or cats in the vehicle. If the vehicle returns with pet dirt or odor, you will be charged for detailing, cleaning and deodorizing the vehicle. The charge is a minimum $100, depending on the work necessary. This is a strict policy that will be enforced. Please be considerate of others.</p>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Accidents} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>In Case of Accident</h6>
                <p>Please call 911, fill out the Accident Report at the time of the accident and thereafter you must get the Police Report as soon as its available for pickup. Call our Maintenance/Accident phone number which is on the emergency info page in your car. If our office is closed please leave message via voicemail or email and we will contact you shortly. 
                In the event of any accident involving another vehicle, there is always some follow up paper work needed from the renter (possibly several weeks after the accident). You must comply with our Fleet Manager requests for you to come in to our office and fill out any necessary paperwork and be available to do so in a timely fashion. Failure to do this will make you solely responsible and liable for any and all damages to any or all vehicles or property involved in the accident.</p>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Tolls} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>Tolls and Tickets</h6>
                <p>The renter is totally and solely responsible for all parking, traffic, TLC, E-Z PASS or any other type of ticket or violation during the time of the rental. 
                For parking and camera violations, Buggy will attempt to transfer the ticket liability to the driver's name. Buggy will NOT be paying the ticket, the driver is responsible to pay for all ticket violations. If we are unable to transfer liability to the driver's name, Buggy will pay the ticket on the driver's behalf and will charge the driver the ticket cost. In BOTH of these cases the driver will be charged a $10 service fee.
                It is the driver's responsibility to have a working EZ-Pass transponder in the vehicle. If Buggy receives an EZ-pass or toll violation, we will pay the toll violation and will charge the driver the cost of the toll plus a $5 service fee per violation.</p>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Repairs} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>Repairs</h6>
                <p>No repairs of any kind, no matter how large or small may be done to the rental vehicle without written approval of the management. If a repair is done without approval, the Renter will be fully responsible for any and all additional repair work that might be needed in order to properly repair the rental vehicle and to bring it back to its pre-rental condition.</p>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={Tires} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>Flat or Punctured Tires</h6>
                <p>Wear and tear to tires is natural and Buggy bears the cost of replacement tires that have low treads due to many hours of regular driving. (Our vehicles get new tires at least twice a year). We will not replace tires that have been damaged due to potholes, nails or any other kind of damage caused by a renter other than standard wear and tear.</p>
            </Col>
            <Col xs={12} sm={{ size: 6}}>
                <div>
                    <Media>
                        <Media href="#">
                            <Media object src={TlcRules} width="100%" className="textCenter" alt="Buggy TLC"/>
                        </Media>
                    </Media>
                </div>
                <h6>TLC Rules</h6>
                <p>Our vehicles cannot be used for street hails. Buggy takes no responsibility to educate any renter in regards to the do's and don'ts of the TLC/DMV (or any other regulatory bodies) rules. It is the sole responsibility of the renter to know the laws and abide by them.</p>
            </Col>
        </Row>
        )}
}
export default Policies