import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import client from './GraphQL.js';
import './App.css';
import RoutingInfo from './RoutingInfo'
import {
  BrowserRouter as Router
} from 'react-router-dom'
import {StripeProvider} from "react-stripe-elements";


class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
          {/* <StripeProvider apiKey="pk_live_Auf0evEtVovrOFK456S4OwTi"> */}
            <Router>
              <RoutingInfo/>
            </Router>
          {/* </StripeProvider> */}
      </ApolloProvider>
     
    );
  }
}

export default App;
